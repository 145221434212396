import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img 
        src="https://img-13.stickers.cloud/packs/ce6cc0de-56fb-4839-a818-66927d5fd900/webp/static/b496a136-bacd-42da-853b-0b860107173d.webp" height="150px"/>
        <p>
          Someone is up around doing detective work huh?
        </p>
      </header>
    </div>
  );
}

export default App;
